<template>
    <div class="notification-wrapper">
        <div :class="'notificationd alert ' + alertClass"
             v-show="show"
             v-text="notification"
             @click="destroyNotification"></div>

    </div>
</template>

<style>

    .notificationd {
        position:fixed;
        z-index: 9999;
        /*display: block;*/
        width: 32%;
        height: 15%;
        opacity: 1;
        right: 0;
        background: #ffd501;
        border-radius: 7px;
        bottom: 0;
    }
    .notificationd:after{
        content: 'Comanda noua...se face refresh la pagina';
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        height: 100%;
        z-index: 999999;
        color: #000;
        font-size: 21px;
    }
</style>

<script>
    Vue.config.productionTip = false;
    export default {
        props: ['type', 'message'],

        data() {
            return {
                show: false,
                notification: this.message,
                alertClass: this.type,
                hideTimeout: false
            }
        },
        mounted(){
            // var audio = new Audio('https://soundbible.com/mp3/old-fashioned-door-bell-daniel_simon.mp3');
            // audio.muted = true;
            // this.showNotification()
            // this.hideNotification()
            Echo.channel('order-tracker')
                        .listen('OrderReceived', (order) => {
                            this.showNotification()
                            // audio.click();
                            document.getElementById('alarm').play();
                            document.getElementById('alarm').muted = false;
                            // audio.muted = false;
                        });
        },
        created () {
            // this.hideNotification()
            // if (this.notification) {
            //     this.showNotification();
            // }
            //
            // window.events.$on('showNotification', (message, type) => {
            //     this.notification = message;
            //     this.alertClass = type;
            //     this.showNotification();
            // });
        },

        methods: {
            showNotification() {
                this.show = true;
                this.hideNotification();
            },

            hideNotification() {
                this.hideTimeout = setTimeout(() => {
                    this.show = false;
                    location.reload()
                }, 10000);
            },

            destroyNotification() {
                this.show = false;
                clearTimeout(this.hideTimeout);
            }
        }
    }
</script>
