require('./bootstrap');
import Vue from 'vue/dist/vue.min.js'
window.Vue = require('vue');



window.onscroll = function() {stickyHeader()};

Vue.component('order-alert', require('./components/OrderAlert.vue').default);

var header = document.getElementById("main-header");
var sticky = header.offsetTop;

function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}
$('.close-icon').on('click', function () {
    $('.notification').hide();
});

const app = new Vue({
    el: '#just',
    //
    // mounted() {
    //     Echo.channel('order-tracker')
    //         .listen('OrderReceived', (e) => {
    //             console.log('omgggg realtime bro')
    //         });
    // }
});